import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import axios from "axios";
import TextArea from "antd/lib/input/TextArea";
import getEnvValue, { getJSON } from "../../utils";
import { VerticalAlignTopOutlined } from "@ant-design/icons";
const { Option } = Select;

const AddOrUpdateModalDServices = (props) => {
  const { visible, onCancel } = props;
  const [Loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(false);
  const [value, setValue] = useState("EN");

const serverURL = "https://www.hmatechs.com";
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
    setValue("EN");
    if (props.type === "EDIT") {
      form.setFieldsValue({
        ...props.record,
      });
    } else {
    }
  }, [form, props.record, props.visible, props.type]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
    try {
      if (info.file.status !== "uploading") {
        console.log("filee", info.file);

        var bodyFormData = new FormData();

        bodyFormData.append("image", info.file.originFileObj);
        form.setFieldsValue({
          image: serverURL + "/images/" + info?.file.originFileObj.name,
        });
        setImageURL(true);
        await axios({
          method: "post",
          url: serverURL + "/api/upload",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleonfinish = async (val) => {
    const config = {
      headers: {
        authorization: getJSON(localStorage.getItem("token")),
      },
    };

    const values = {
      ...val,
      id: props.record._id,
    };

    if (props.type === "EDIT") {
      await axios
        .put(
          "http://151.80.78.31:86/api/services/edit/" + values.id,
          {
            title: val.title,
            titleFR: val.titleFR,
            titleAR: val.titleAR,

            image: form.getFieldValue("image"),

            description: val.description,
            descriptionFR: val.descriptionFR,
            descriptionAR: val.descriptionAR,
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Update Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    } else {
      console.log("from", form.getFieldValue("data"));
      await axios
        .post(
          "http://151.80.78.31:86/api/services/",
          {
            title: form.getFieldValue("title"),
            titleFR: form.getFieldValue("titleFR"),
            titleAR: form.getFieldValue("titleAR"),

            image: form.getFieldValue("image"),
            description: form.getFieldValue("description"),
            descriptionFR: form.getFieldValue("descriptionFR"),
            descriptionAR: form.getFieldValue("descriptionAR"),
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Create Done " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleonfinish}
      preserve={props.type === "EDIT" ? true : false}
    >
      <div className="site-card-border-less-wrapper">
        <Modal
          title={props.type === "EDIT" ? "UPDATE" : "CREATE"}
          centered
          visible={visible}
          destroyOnClose
          onOk={() => {
            form.submit();
          }}
          onCancel={onCancel}
        >
          <Card
            centered
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Radio.Group
              defaultValue={value}
              buttonStyle="solid"
              style={{
                marginBottom: "10px",
              }}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            >
              <Radio.Button value="EN">EN</Radio.Button>
              <Radio.Button value="FR">FR</Radio.Button>
              <Radio.Button value="AR">AR</Radio.Button>
            </Radio.Group>

            <br />

            <Row justify="space-between" gutter={16}>
              <Col span={24}>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => (
                    <Form.Item name="fa_icon" rules={[]}>
                      <Upload
                        name="slideimg"
                        listType="picture-card"
                        className="avatar-uploader projects-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {imageURL || props?.record?.image ? (
                          <img
                            src={getFieldValue("image") ?? props?.record?.image}
                            alt="slideimg"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div className="ant-upload-text font-semibold text-dark">
                            {
                              <VerticalAlignTopOutlined
                                style={{ width: 20, color: "#000" }}
                              />
                            }
                            <div>Upload New Image</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
            </Row>

            {value === "EN" ? (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please input your title!",
                      },
                    ]}
                  >
                    <Input placeholder="title" type="texte" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Description!",
                      },
                    ]}
                  >
                    <TextArea rows={9} placeholder="Description" type="texte" />
                  </Form.Item>
                </Col>
              </Row>
            ) : value === "FR" ? (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="titleFR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your title FR!",
                      },
                    ]}
                  >
                    <Input placeholder="title FR" type="texte" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="descriptionFR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Description FR!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={9}
                      placeholder="Description FR"
                      type="texte"
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="titleAR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your title AR!",
                      },
                    ]}
                  >
                    <Input placeholder="title AR" type="texte" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    name="descriptionAR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Description AR!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={9}
                      placeholder="Description AR"
                      type="texte"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Card>
        </Modal>
      </div>
    </Form>
  );
};

export default AddOrUpdateModalDServices;
