import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { notification } from "antd";
import axios from "axios";
import { UploadOutlined, VerticalAlignTopOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import getEnvValue, { getJSON } from "../../utils";
const { Option } = Select;

const AddOrUpdateModalTeams = (props) => {
  const { visible, onCancel } = props;
  const [Loading, setLoading] = useState(false);
  const [imageURL, setImageURL] = useState(false);
  const [value, setValue] = useState("EN");

  const serverURL = "https://www.hmatechs.com";

  const [form] = useForm();

  useEffect(() => {
    if (props.type === "EDIT") {
      form.setFieldsValue({
        ...props.record,
      });
    } else {
    }
  }, [form, props.record, props.visible]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = async (info) => {
    if (info.file.status === "uploading") {
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) => {
        setLoading(false);
        setImageURL(false);
      });
    }
    try {
      if (info.file.status !== "uploading") {
        console.log("filee", info.file);

        var bodyFormData = new FormData();

        bodyFormData.append("image", info.file.originFileObj);
        form.setFieldsValue({
          image: serverURL + "/images/" + info?.file.originFileObj.name,
        });
        setImageURL(true);
        await axios({
          method: "post",
          url: serverURL + "/api/upload",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleonfinish = async (val) => {
    const config = {
      headers: {
        authorization: getJSON(localStorage.getItem("token")),
      },
    };

    const values = {
      ...val,
      id: props.record._id,
    };

    if (props.type === "EDIT") {
      await axios
        .put(
          "http://151.80.78.31:86/api/teamsEvents/edit/" + values.id,
          {
            fullName: values.fullName,
            position: values.position,

            fullNameFR: values.fullNameFR,
            positionFR: values.positionFR,

            fullNameAR: values.fullNameAR,
            positionAR: values.positionAR,

            phone: values.phone,
            email: values.email,
            linkedIn: values.linkedIn,
            github: values.github,
            whatsapp: values.whatsapp,
            image: values.image,
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Update Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    } else {
      console.log("from", form.getFieldValue("data"));
      await axios
        .post(
          "http://151.80.78.31:86/api/teamsEvents",
          {
            fullName: form.getFieldValue("fullName"),
            position: form.getFieldValue("position"),

            fullNameFR: form.getFieldValue("fullNameFR"),
            positionFR: form.getFieldValue("positionFR"),

            fullNameAR: form.getFieldValue("fullNameAR"),
            positionAR: form.getFieldValue("positionAR"),

            phone: form.getFieldValue("phone"),
            email: form.getFieldValue("email"),

            linkedIn: form.getFieldValue("linkedIn"),
            github: form.getFieldValue("github"),
            whatsapp: form.getFieldValue("whatsapp"),

            image: form.getFieldValue("image"),
          },
          config
        )
        .then((response) => {
          notification.success({ message: "Create Done  " });
          props.refetech();
          onCancel();
        })
        .catch(function (err) {
          props.refetech();
          onCancel();
        });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleonfinish}
      preserve={props.type === "EDIT" ? true : false}
    >
      <div className="site-card-border-less-wrapper">
        <Modal
          name={props.type === "EDIT" ? "UPDATE" : "CREATE"}
          centered
          visible={visible}
          destroyOnClose
          onOk={() => {
            form.submit();
          }}
          onCancel={onCancel}
        >
          <Card
            centered
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Radio.Group
              defaultValue={value}
              buttonStyle="solid"
              style={{
                marginBottom: "10px",
              }}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            >
              <Radio.Button value="EN">EN</Radio.Button>
              <Radio.Button value="FR">FR</Radio.Button>
              <Radio.Button value="AR">AR</Radio.Button>
            </Radio.Group>

            <br />

            <Row justify="space-between" gutter={16}>
              <Col span={24}>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => (
                    <Form.Item
                      name="image"
                      rules={[
                        {
                          required: true,
                          message: "Please input your image!",
                        },
                      ]}
                    >
                      <Upload
                        name="slideimg"
                        listType="picture-card"
                        className="avatar-uploader projects-uploader"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                      >
                        {imageURL || props?.record?.image ? (
                          <img
                            src={getFieldValue("image") ?? props?.record?.image}
                            alt="slideimg"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <div className="ant-upload-text font-semibold text-dark">
                            {
                              <VerticalAlignTopOutlined
                                style={{ width: 20, color: "#000" }}
                              />
                            }
                            <div>Upload New Slide</div>
                          </div>
                        )}
                      </Upload>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>

              {value === "EN" ? (
                <Col span={24}>
                  <Form.Item
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your fullName!",
                      },
                    ]}
                  >
                    <Input placeholder="fullName" type="texte" />
                  </Form.Item>
                </Col>
              ) : value === "FR" ? (
                <Col span={24}>
                  <Form.Item
                    name="fullNameFR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your fullName!",
                      },
                    ]}
                  >
                    <Input placeholder="fullName" type="texte" />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24}>
                  <Form.Item
                    name="fullNameAR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your fullName!",
                      },
                    ]}
                  >
                    <Input placeholder="fullName" type="texte" />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="email" type="texte" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone!",
                    },
                  ]}
                >
                  <Input placeholder="phone" type="texte" />
                </Form.Item>
              </Col>

              {value === "EN" ? (
                <Col span={24}>
                  <Form.Item
                    name="position"
                    rules={[
                      {
                        required: true,
                        message: "Please input your position!",
                      },
                    ]}
                  >
                    <Input placeholder="position" type="texte" />
                  </Form.Item>
                </Col>
              ) : value === "FR" ? (
                <Col span={24}>
                  <Form.Item
                    name="positionFR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your position!",
                      },
                    ]}
                  >
                    <Input placeholder="position" type="texte" />
                  </Form.Item>
                </Col>
              ) : (
                <Col span={24}>
                  <Form.Item
                    name="positionAR"
                    rules={[
                      {
                        required: true,
                        message: "Please input your position!",
                      },
                    ]}
                  >
                    <Input placeholder="position" type="texte" />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                <Form.Item
                  name="linkedIn"
                  rules={[
                    {
                      required: true,
                      message: "Please input your linkedIn!",
                    },
                  ]}
                >
                  <Input placeholder="linkedIn" type="texte" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="github"
                  rules={[
                    {
                      required: true,
                      message: "Please input your github!",
                    },
                  ]}
                >
                  <Input placeholder="github" type="texte" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="whatsapp"
                  rules={[
                    {
                      required: true,
                      message: "Please input your whatsapp!",
                    },
                  ]}
                >
                  <Input placeholder="whatsapp" type="texte" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Modal>
      </div>
    </Form>
  );
};

export default AddOrUpdateModalTeams;
